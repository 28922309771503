<template>
  <div :class="siteMode === 'UH' ? 'signup-app-uh' : 'signup-app'">
    <v-container>
      <v-row class="text-center">
        <v-col cols="12">
          <v-img
            :src="siteMode === 'UH' ? logoUH : logoVP"
            class="mt-3"
            contain
            height="150"
          />
        </v-col>

        <v-col class="mb-4">
          <h1
            :class="
              siteMode === 'UH'
                ? 'display-2 black--text font-weight-bold'
                : 'display-2 white--text font-weight-bold'
            "
          >
            {{
              siteMode === 'UH' ? 'University Hospitals' : 'Welcome to VPExam'
            }}
          </h1>
        </v-col>
      </v-row>
      <v-row class="justify-center align-center" style="height: 100vh;">
        <v-col cols="12" md="8">
          <v-card>
            <v-card-text>
              <div>Please set your password.</div>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-alert dense type="error" dismissible v-if="error">
                  {{ error }}</v-alert
                >
                <v-text-field
                  v-model="password"
                  @keyup.enter="changePassword"
                  :type="show ? 'text' : 'password'"
                  :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="show = !show"
                  :rules="nameRules"
                  label="Password"
                  required
                ></v-text-field>

                <v-btn
                  :disabled="!valid"
                  color="primary"
                  @click="changePassword"
                  block
                >
                  Submit
                </v-btn>
                <v-btn
                  color="primary"
                  class="mt-2 text-left"
                  style="text-transform: none;"
                  text
                  to="/login"
                  block
                >
                  <span class="mr-1 grey--text">Already have an account?</span
                  >Login
                </v-btn>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import PublicLayout from '@/layouts/PublicLayout'
import * as fb from '@/firebase'
import logoUH from '@/assets/logo.svg'
import logoVP from '@/assets/logo-white.png'

export default {
  name: 'SetPassword',
  data: () => ({
    logoUH: logoUH,
    logoVP: logoVP,
    siteMode: process.env.VUE_APP_SITE,
    error: '',
    valid: true,
    show: false,
    password: '',
    nameRules: [v => !!v || 'Required'],
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
    ],
    select: null
  }),
  created() {
    this.$emit(`update:layout`, PublicLayout)
  },
  methods: {
    async changePassword() {
      if (this.$refs.form.validate()) {
        try {
          await fb.auth.currentUser.updatePassword(this.password)
          if (fb.auth.currentUser.displayName == 'patient') {
            this.$router.push('/my-appointments')
          } else if (fb.auth.currentUser.displayName == 'partner') {
            this.$router.push('/partner/appointments')
          }
        } catch (error) {
          this.error = error.message
          this.loading = false
        }
      }
    }
  }
}
</script>
